<template>
  <section class="deleted-list">
    <el-table
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column  :resizable="false"
        prop="eventName"
        label="事件名称"
        align="center"
        width="180">
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="createTime"
        label="创建时间"
        align="center">
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="startTime"
        align="center"
        label="起始时间">
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="endTime"
        align="center"
        label="结束时间">
      </el-table-column>
      <el-table-column  :resizable="false"
        align="center"
        width="180"
        label="操作">
        <template scope="{ row }">
          <el-switch
            style="display: block"
            v-model="row.state"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
    <section class="list-bottom">
      <span class="count">共{{count}}条记录</span>
      <el-pagination
        :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </section>
  </section>
</template>

<script>
export default {
  data () {
    return {
      tableData: [
        {
          eventName: '万达',
          createTime: '2019-11-11 22:38',
          startTime: '2019-11-11 22:38',
          endTime: '2019-11-11 22:38',
          state: true
        },
        {
          eventName: '万达',
          createTime: '2019-11-11 22:38',
          startTime: '2019-11-11 22:38',
          endTime: '2019-11-11 22:38',
          state: true
        },
        {
          eventName: '万达',
          createTime: '2019-11-11 22:38',
          startTime: '2019-11-11 22:38',
          endTime: '2019-11-11 22:38',
          state: true
        }
      ],
      count: 2,
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4
    }
  }
}
</script>

<style lang="scss">
.deleted-list {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
  border-radius: 10px;
  padding: 40px 30px;
  position: relative;
  .el-table {
    margin-bottom: 50px;
  }
  .list-bottom {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    .count {
      line-height: 32px;
    }
  }
}
</style>
